
.ui-select-container.ui-select-bootstrap {
  padding-top: 6px;
  padding-bottom: 6px;
}

.ui-select-container.ui-select-bootstrap input.ui-select-search {
  max-width: 100%;
}

.criteriaBlock {
  display: inline-flex;
  float: none;
}

.criteriaElement {
  width: 100%;
  border: 1px dashed rgba(63, 63, 63, 0.3);
  background-color: #E7E8ED;
  /*border-radius: 1em;*/
  font-size: 12px;
  padding: 8px 15px 15px;
  display: inline-block;
  margin: 0 0 5px 0;
  color: rgba(32, 32, 32, 0.8);
}

.criteriaElement .headerAction {
  position: relative;
  top: -8px;
  left: 12px;
  cursor: pointer;
  color: rgba(63, 63, 63, 0.8);
  float: right;
}

.criteriaElement .btn-default {
  color: black;
}

.criteriaElement input {
  width: 100%;
}

.criteriaElement .criteriaMin {
  padding: 0 5px 0 0;
}
.criteriaElement .criteriaMax {
  padding: 0 0 0 5px;
}

.criteriaElement .header {
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.other-stats {
  padding: 10px 10px 2px 10px;
}

.result-block {
  display: flex;
  display: -webkit-flex;
  background: #fff;
}

.result-block-left {
  -webkit-flex: 1;
  flex: 1;

}

.result-block-right {
  -webkit-flex: 3;
  flex: 3;

}

.result-block .breakdown th {
  font-size: 1.3rem;
  font-weight: 700;
  color: #3a4462;
  background: rgba(229,236,248,0.95);
  border-bottom: 1px solid #ddd;
}

.result-block .result th, .strategy-block th {
  text-transform: uppercase;
  font-size: 1.3rem;
  color: #3a4462;
  background: rgba(229,236,248,0.95);
}

.result-block .primary-breakdown:hover {
  background: rgba(229,236,248,0.95);
}

.result-block .primary-breakdown td:hover {
  cursor:pointer;;
}

.result-block .secondary-breakdown {
  /* cursor:pointer; */
  text-transform: uppercase;
  background: rgba(229,236,248,0.95);
}

.result-block .secondary-breakdown td {
  font-weight: 100;
  text-align: right;
}

.result-block .secondary-breakdown td:first-child  {
  text-align: left;
}


.result-block .secondary-breakdown table {
  width: 100%;
}

.result-block .secondary-breakdown table td.ng-binding {
  text-align: right;
  padding-right: 25px;
}


.result-block .result tr.primary-breakdown td:first-child {
  text-align: left;
  border-right: 1px solid #ddd;
  font-weight: 700;
}

.result-block .result tr th:first-child {
  text-align: center;
}

.result-block .fa-times-circle-o {
  cursor: pointer;
  color: rgba(104, 117, 119, 0.8);
  margin-right: 3px;
}

.result-row {
  margin-top: 5px;
  padding: 0 15px;
  background-color: #fff;
}

.result-row h1,h2 {
  color: #9C28FF;
  font-weight: lighter;
}

.result-row .summary-box {
  height: 100px;
  border: solid 1px;
  text-align: center;
  border-color: rgba(104,117,119,.15);
  margin: 5px 0;
  background-color: rgba(0,158,205,0.05);
}

.result-row .summary-box h2 {
  font-size: 2.2rem;
}



.result-view i {
  /* padding: 0 5px; */
  cursor: pointer;
}

.result-view {
  color: rgba(104,117,119,0.5);
  margin-top: 15px;
  margin-left: 10px;
}

.result-view .active {
  color: rgba(0,158,205,0.5);
}

#results-start {
  margin-bottom: 90px;
}

.confidence .alert {
  margin-top: 15px;
  padding: 10px 35px 15px 10px;
  border-radius: 0px;
}

.confidence .confidence-icon {
  display: inline-block;
  padding-right: 40px;
  float: left;
}

.confidence .alert p {
  text-transform: uppercase;
}


/* Row highlighting */

tr.row-highlight-roi {
  background-color: rgba(160, 16, 15, 0.20);
}

tr.row-highlight-count {
  background-color: rgba(0, 158, 205, 0.20);
}

tr.row-highlight-count.row-highlight-roi  {
  background-color: rgba(160, 34, 153, 0.20);
}


.stats-footer {
  border-top: 2px solid #ddd;
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 90px;
  padding-top: 20px;
  z-index: 900;
}

.stats-footer .btn {
  font-weight: bold;
  text-transform: uppercase;
}

.stats-footer .matches {
  text-transform: uppercase;
  text-align: center;
}

#add-breakdown .ui-select-placeholder {
  display: inline-block;
  text-align: center;
  width: 100%;
}

.search-market-link:hover {
  background: rgba(229,236,248,0.95);
  cursor:pointer;
}

#search-criteria uib-accordion div.panel-body {
  max-height: 250px;
  overflow-y: auto;
}

// Tables

th.tablesort-sortable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
}

table .tablesort-sortable:after{
  content:"";
  float:right;
  margin-top:7px;
  visibility:hidden;
  border-left:4px solid transparent;
  border-right:4px solid transparent;

  border-top:none;
  border-bottom:4px solid #000;
}

table .tablesort-desc:after{
  border-top:4px solid #000;
  border-bottom:none;
}

table .tablesort-asc,table .tablesort-desc{
  background-color:rgba(141, 192, 219, 0.25);
}

table .tablesort-sortable:hover:after, table .tablesort-asc:after, table .tablesort-desc:after {
  visibility:visible;
}

/*
 * Styling for the table row shown in empty tables
 */

/* The row is always added as the first row in a table
   Hide it by default */
.showIfLast {
  display: none;
}

/* Only show it if it is also the last row of the table. */
.showIfLast:last-child {
  display: table-row;
}

.showIfLast td {
  text-align: center;
}

.showIfLast td:after {
  content: "No data";
}