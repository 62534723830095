/* Needed for hiding scrollbars when pushing */
html {
  overflow-x: hidden;
}

.ng-pageslide {
  background: rgb(248, 250, 254);
  padding-top: 53px;
  overflow-y: auto !important;
  border-left: dotted;
  border-left-width: 1px;
}

.ng-pageslide .container {
  width: 100%;
}

.ng-pageslide .container .fa-times-circle-o {
  cursor: pointer;
  float: right;
  margin: -20px -10px;
}

.ng-pageslide .content {
  padding-left: 25px;
  padding-right: 25px;
}

body.ng-pageslide-body-open::before {
  content: '.';
  display: block;
  position: fixed;
  top: 0;
  background-color: rgb(0, 0, 0);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.5;
  transition: opacity 1s;
}

body.ng-pageslide-body-closed::before {
  transition: opacity 1s;
  opacity: 0;
  content: '.';
  display: block;
  position: fixed;
  top: 0;
  background-color: rgb(0, 0, 0);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}
