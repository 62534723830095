@import '../../../../vendor/bower_components/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.scss';

.checkbox.criteria {
  max-height: 250px;
  overflow: auto;
  padding-bottom: 10px;
}

.criteria-edit {
  margin-left:10px;
}

.checkbox label {
  vertical-align: top;
}

.checkbox label::before {
  width: 16px;
  height: 16px;
  margin: 3px 0 0 -20px;
  padding: 0;
}

.checkbox label::after {
  padding-top: 4px;
}