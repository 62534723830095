li.accent-line {
  height: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-top-style: dotted;
  border-top-width: 1px;
  width: 50px;
  color: $btn-default-border;
  opacity: 0.50;
}
.strategy-block {
  padding-bottom: 25px;
}

.strategy-table tr:last-child {
  text-transform: uppercase;
  color: rgba(104,117,119,.8);
  font-weight: lighter;
}

.strategy-enabled {
  color: #00A000;
}

.strategy-disabled {
  color: rgba(160, 35, 23, 0.55);
}

.icon-lendingclub, .icon-1 {
  background-image: url("../img/logos/lendingclub_logo.png");
  width: 16px;
  height: 16px;
  display: inline-block;
}

.icon-prosper, .icon-2 {
  background-image: url("../img/logos/prosper_logo.png");
  width: 16px;
  height: 16px;
  display: inline-block;
}

.icon-fundingcircle, .icon-3 {
  background-image: url("../img/logos/fundingcircle_logo.png");
  width: 16px;
  height: 16px;
  display: inline-block;
}

.icon-fundrise, .icon-10 {
  background-image: url("../img/logos/kickfurther_logo.png");
  width: 16px;
  height: 16px;
  display: inline-block;
}


.icon-fundrise, .icon-11 {
  background-image: url("../img/logos/fundrise_logo.png");
  width: 16px;
  height: 16px;
  display: inline-block;
}

.progress-wrapper{
  position: relative;
  margin:2px 10px 2px 10px;
  font-size: 12px;
  border: none;
}

.progress{
  position: absolute;
  color: $text-color;
  background: $off-white;
  font-weight: 100;
  min-height: 80px;
  border: none;
  box-shadow: none;
  margin-top: 10px;
  /*line-height: 2;*/
}

.feature-even {
  padding-top: 10px;
  padding-bottom: 10px;
  background: darken($off-white, 1);

}

.feature-odd {
  padding-top: 10px;
  padding-bottom: 10px;

}


/* From JIRA: The black buttons needs a completely opposite color when selected - it's too hard to tell what is selected and what is not. Maybe use HEX #: CFE6FF when unselected and #: 4D9AF0 on select */

.lighter-buttons .btn-group .btn-primary {
  background-color: #adcdff;
  border-color: #72b5f0;
}

.lighter-buttons .btn-group .btn-primary.active {
  background-color: #418df0;
}

.lighter-buttons .rz-bar {
  background-color: #adcdff;
}

.lighter-buttons .rz-bar.rc-selection {
  background-color: #418df0;
}

.strategy-table tbody tr td {
    border-top: 0;
}