.grid {
  display: flex;
}

.grid-cell-1, .grid-cell {
  flex: 1;
}

.grid-row-top {
  align-items: flex-start;
}
.grid-row-bottom {
  align-items: flex-end;
}
.grid-row-center {
  align-items: center;
}

.grid-cell-top {
  align-self: flex-start;
}
.grid-cell-bottom {
  align-self: flex-end;
}
.grid-cell-center {
  align-self: center;
}


.row.row-equal {
   overflow: hidden;
}

.row.row-equal > [class*="col-"] {
     margin-bottom: -99994px;
     padding-bottom: 99999px;
}

