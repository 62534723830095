.simple-mode-row {
  margin: 15px 0px;
}

h3.agreement-header {
  margin-bottom: 25px;
}

rzslider span.rz-pointer {
  top: -12px;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid;
  border-color: rgba(144, 157, 159, .8)
}

.disclosure {
  font-style: italic;
  color: #868586;
  padding: 30px 0;
}

hr {
  margin: 15px 0;
  border: none;
  background-image: linear-gradient(to right, #808080 25%, rgba(255, 255, 255, 0) 0%);
  background-size: 9px 2px;
  background-repeat: repeat-x;
  height: 2px;
}