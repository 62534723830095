/**
https://github.com/twbs/bootstrap-sass/blob/master/assets/stylesheets/bootstrap/_variables.scss
*/
$nsr-font:              "NSR";
$dark-background:       #3D0B6D;
$dark-background-link:  #fff;
$gray-nsr:              #eee;
$off-white:             #fcfdff;
$active:                #286090;
$body-bg:               #1a032e;

$navbar-default-color:             #777;
$navbar-default-bg:                $dark-background;
$navbar-default-border:            darken($navbar-default-bg, 6.5%);
$navbar-default-link-color: #ffffff;
$navbar-default-link-hover-color:  lighten($navbar-default-link-color, 20%);
$navbar-height:                    50px !default;


$btn-border-radius-base: 25px;
$btn-border-radius-small: 25px;

//$dropdown-link-color: #ABB7D1;

$brand-primary:       rgb(59, 63, 72);
$brand-info: rgb(150, 203, 253);
$brand-success: $brand-primary;
$brand-danger: #EF6860;
$brand-warning: #eed26f;

$btn-font-weight:                normal;

$btn-default-color:              rgb(178, 191, 215);
$btn-default-bg:                 #fff;
$btn-default-border:             #b4b3b3;


$state-success-text: $brand-success;
$state-warning-text: $brand-warning;

$alert-warning-text:            #fff;
$state-warning-bg: $brand-warning;

$alert-danger-text:              #fff;
$state-danger-bg:                $brand-danger;

//$state-info-text:              #fff;
//$state-info-bg: #4285BA;


@import 'bootstrap-custom';

// Helpers
@import 'helpers/_extends';
@import 'helpers/_mixins';

// TODO: Switch to bower component
@import 'helpers/animate';

// Base
// Most of the base styles are taken care of by twbs-bootstrap-sass bower component
@import 'base/global';
@import 'base/typography';
@import 'base/accessibility';
@import 'base/grid';



// Layout
// @import 'layout/layout';

// Modules
// TODO: Switch to bower component
@import 'module/ui-select';

@import 'module/dashboard';
@import 'module/navigation';
// @import 'module/buttons';
// @import 'module/footer';
// @import 'module/media';

@import 'module/icons';
@import 'module/checkboxes';

@import 'module/backtester';
@import 'module/signup';
@import 'module/slider';
@import 'module/strategy-and-account';
@import 'module/simple-mode';
@import 'module/pageslide';

//@import '../../../vendor/bower_components/animate.css/animate.min.css';

/*
@media only screen and (min-width : 768px) {

  .dropdown:hover .dropdown-menu {
    display: block;
  }
}*/
// State

// Utilities
// @import 'utility/utility';

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none;
}

.ng-invalid {
  border-color: $brand-danger !important;
}

.desaturate {
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
}

.navbar-nav a {
  font-weight: 400;
  font-size: 18px;
}


.range-selector {
  min-height: 40px;
  font-size: 14px;
  color: black;
  padding-left: 5px;
}

.criteriaElement .icon-actions {
  padding-left: 10px;
  font-size: 14px;
}

.bubble-white {
  min-height: 220px;
  padding: 5px;
  background: white;
  margin: 2px;
  border: solid;
  border-radius: 10px;
  border-width: 1px;
}

.bubble-white > h3 {
  color: $brand-primary;
}

.scope-1 {
  background-color: rgba($brand-info, 0.4)
}

.scope-2 {
  background-color: rgba($brand-warning, 0.4)
}

.scope-3 {
  background-color: rgba($brand-danger, 0.4)
}

.light-label {

  text-transform: uppercase;

}

.accordion-toggle {
  padding-left: 5px;
  font-weight: bold;
}

.loan-grade {
  padding: 2px 4px 2px 4px;
  color: #fff;
  display: inline-block;
  border-radius: 2px;
  width: 26px;
  text-align: center;
}
// Lending Club
.loan-grade.lc-a {
  background-color: #5578a7;
}

.loan-grade.lc-b {
  background-color: #6cc5e3;
}

.loan-grade.lc-c {
  background-color: #5fb634;
}

.loan-grade.lc-d {
  background-color: #86dc00;
}

.loan-grade.lc-e {
  background-color: #fae202;
}

.loan-grade.lc-f {
  background-color: #ffc502;
}

.loan-grade.lc-g {
  background-color: #ffa302;
}
// Prosper

.loan-grade.p-aa {
  background-color: rgb(200, 214, 52);
}

.loan-grade.p-a {
  background-color: rgb(50, 201, 199);
}

.loan-grade.p-b {
  background-color: rgb(120, 191, 31);
}

.loan-grade.p-c {
  background-color: rgb(232, 86, 146);
}

.loan-grade.p-d {
  background-color: rgb(254, 192, 34);
}

.loan-grade.p-e {
  background-color: rgb(243, 110, 104);
}

.loan-grade.p-hr {
  background-color: rgb(191, 62, 100);
}


.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



