// Dashboard styles

$blue: #232323;
$light-blue: #232323;
$available-funds: #232323;
$projected-growth: #232323;
$white:     #fff;
$off-white:             #fcfdff;

.dashboard-row {
  margin-top: 30px;
}
.dashboard-col {
  padding-left: 0;
  padding-right: 0;
}

/** Colors */
.light-blue {
  color: #cddff7;
}
.progress-bar-light-blue {
  background-color: #cddff7;
}
.progress-bar-orange {
  background-color: #f45f4d;
}
.blue {
  color: #9ccafc;
}
.progress-bar-blue {
  background-color: #9ccafc;
}
.dark-blue {
  color: #6d8fd9;
}
.progress-bar-dark-blue {
  background-color: #6d8fd9;
}
.progress-bar-light-grey {
  background-color: #e4e7f0;
}
.progress-bar-lighter-grey {
  background-color: #f9faff;
}
.light-grey {
  color: #e4e7f0;
}
.primary {
  color: #f45f4d;
}

/* BALANCE & RETURNS SECTION */
.graph i, .returns-tab-1 i {
  font-size: 11px;
  padding-left: 10px;
}
.graph h2, .returns-tab-1 h2 {
  margin-top: 0px;
}


/* PROGRESS BAR */
.progress-bar {
  box-shadow: none;
  transition: width .6s ease;
}
.grade .progress-bar {
  margin-top: 8px;
}
.progress {
  height: 16px;
  margin-bottom: 3px;
  box-shadow: none!important;
}
.progress-mini {
  height: 3px;
  width: 110px;
  margin-bottom: 3px;
  margin-top: 3px;
  box-shadow: none!important;
}
.progress-small{
  height: 7px;
  width: 100%;
  margin-bottom: 0px;
  margin-top: 0px;
  box-shadow: none!important;
}
.progress-label {
  display: block;
  width: 70%;
  height: 12px;
  margin-top: 10px;
  border-left: 2px solid #e8ebf4;
  border-right: 2px solid #e8ebf4;
  border-bottom: 2px solid #e8ebf4;
}
.box-1 {
  width: 35%;
  height: 12px;
  border-right: 2px solid #e8ebf4;
}
.box-2 {
  width: 23%;
  height: 6px;
  border-right: 1px solid #9ccafc;
}
.box-3 {
  width: 61px;
  height: 6px;
  border-right: 1px solid #9ccafc;
}
.label-figure {
  position: relative;
  left: -15%;
  font-size: 24px;
  font-weight: 300;
}
.rounded-left {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.rounded-right {
  border-top: 2px dashed #6d8fd9;
  border-bottom: 2px dashed #6d8fd9;
  border-right: 2px dashed #6d8fd9;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}


.spacer-30 {
  padding-top: 30px;
}

.spacer-10 {
  padding-top: 10px;
}

.radius {
  border-radius: 10px!important;
}


.toast-info {
  background-color: #24262c;
}

.toast-loading {
  background-color: #2f96b4;
}

#toast-container > .toast-loading {
  background-image: none;
}



.breakdown-table tr td {
  border-top: none !important;
}
