/* Signup Wizard */
/* style.css */

/* form styling */
#form-container {
  background: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

#form-container .page-header {
  // background: #fff;
  margin: 0;
  padding: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* numbered buttons */
#status-buttons {

}

#status-buttons a {
  color: #000;
  display: inline-block;
  font-size: 12px;
  margin-right: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 10%;

}

#status-buttons a:hover {
  text-decoration: none;
}
#status-buttons a:active {
  text-decoration: none;
}

/* we will style the span as the circled number */
#status-buttons span.bubble {
  background: #687B8D;
  display: block;
  height: 30px;
  margin: 0px auto 10px;
  padding-top: 7px;
  width: 30px;
  color: #fff;
  border-radius: 50%;
  font-weight: 600;
}

/* active buttons turn light green-blue*/
#status-buttons a.active span.bubble, #status-buttons a:active span.bubble {
  background: #E86764;
  text-decoration: none;
}

#managed-plans  .panel-heading {
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
}

#managed-plans .assertive .panel-heading  {
  background-color: #F19F9D;
}

#managed-plans .assertive  .panel-body  {
  color: #fff;
  background-color: #E86764;
}

#managed-plans .balanced .panel-heading  {
  background-color: #8697A6;
}

#managed-plans .balanced  .panel-body  {
  color: #fff;
  background-color: #687B8D;
}

#managed-plans .conservative .panel-heading  {
  background-color: #5C5D8A;
}

#managed-plans .conservative .panel-body  {
  color: #fff;
  background-color: #454667;
}

#managed-plans  h2 {
  font-size: 45px;
}

#managed-plans  .btn-outline  {
  background-color: #fff;
  border: solid 1px;
  border-color: #fff;
  color: rgba(104, 117, 119, 1);
  font-size: 1.4rem;
}


.border .text-center > h2 {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.50);
}

#highlights p {
  text-align: center;
  font-size: 1.9rem;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.50);
}

.border {
  padding: 5px;
  border: solid 1px;
  margin: 10px 15px 0px 15px;
  border-color: rgba(134, 151, 166, 0.30);
  border-radius: 5px;
}

a.advanced {
  font-size: 18px;
}

.divider {
  color: #E86764;
}

.settings i.fa-asterisk {
  margin-right: 5px;
}

.inline-signup label.col-md-4 {
  width: 100%;
  text-align: left;
}
.inline-signup .col-md-6, .inline-signup .col-md-6 .col-md-offset-3 {
  width: 100%;
}
.inline-signup .col-md-offset-3, .inline-signup .col-md-offset-4 {
  margin-left: 0;
}

