body, html{
  background-color: #1a032e !important;
}

.btn {
  /*text-transform: uppercase;*/
}

.strike {
  text-decoration: line-through;

}

.action-block a {
  color: #fff;
}

.subject-block {
  background: $off-white;
  min-height: 450px;
  border-right: solid;
  border-right-color: #e7e8ed;
  border-right-width: 2px;
  padding: 10px 15px 15px;
  margin-top: 2px;
}

.subject-block-md {
  background: $off-white;
  min-height: 300px;
  border-right: solid;
  border-right-color: #e7e8ed;
  border-right-width: 2px;
  padding: 10px 15px 0;
  margin-top: 2px;
}

.subject-block-sm {
  background: $off-white;
  min-height: 150px;
  border-right: solid;
  border-right-color: #e7e8ed;
  border-right-width: 2px;
  padding: 10px 15px 0;
  margin-top: 2px;
}

.subject-block-xs {
  background: $off-white;
  min-height: 75px;
  border-right: solid;
  border-right-color: #e7e8ed;
  border-right-width: 2px;
  padding: 10px 15px 0;
  margin-top: 2px;
}

.error-block {
  background: #E9ECF3;
  border: dashed;
  border-color: #DBDEE5;
  color: #D4D7DE;
  padding: 20px;
}

.error-block > p {
  color: #91949b;

}

.nsr-animate.ng-enter, .nsr-animate.ng-move {
  -webkit-animation: zoomIn 1s;
  -moz-animation: zoomIn 1s;
  -ms-animation: zoomIn 1s;
  animation: zoomIn 1s;
}

.nsr-animate.ng-leave {
  -webkit-animation: zoomOut 1s;
  -moz-animation: zoomOut 1s;
  -ms-animation: zoomOut 1s;
  animation: zoomOut 1s;
}

.panel-heading {
  padding: 0;
}

.page-header {
  margin-top: 0px;
  border-bottom: none;
  // background: white;
}


.accordion-toggle {
  display: block;
  padding: 5px 2px 5px 2px;
}

.btn-sq {
  border-radius: 0px;
}

.btn-dashed {
  border: dashed;
  border-width: 1px;
}

.btn-clear, .btn-clear:focus  {
  background-color: #fff;
  border: none;
  color: rgba(0, 158, 205, 0.80);
}

.btn-clear:hover {
  background-color: #fff;
  border: none;
  color: rgba(0, 158, 205, 0.80);
}

.text-label {
  text-transform: uppercase;
  color: rgb(158, 167, 181);
  font-weight: 200;
  font-size: 1.2rem;
}

.dead-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

/*sign-up from LR*/
.sign-up-header{
  color: white;
}
.sign-up-header-nsr-bg{
  background-color: #3A3D46;
}
.sign-up-header-lr-bg{
  background-color: #3D2D58;
}
.sign-up-footer{
  color: white;
}
.sign-up-footer-nsr-bg{
  background-color: #3A3D46;
}
.sign-up-footer-lr-bg{
  background-color: #5A5D67;
}

.signup-continue.md-button{
  padding: 12px 16px;
  border: 1px solid;
  line-height: inherit;
}

input:not([type]):focus:not([readonly]),
input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #6e1faf;
  box-shadow: 0 1px 0 0 #6e1faf;
}

.alert-box{
  background-color: #9b28ff;
  border: 1px solid #290a44;
}

.btn.btn-warning.btn-block.btn-sq{
  background-color: #1a032e;
  border: none;
  width: fit-content;
  align-self: center;
  border-radius: 5px;
}

.btn.btn-warning.btn-block.btn-sq:hover{
  background-color: #300655;

}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus{
  color: #ffffff;
}

.navbar.navbar-default.navbar-fixed-top{
  height: auto;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media only screen and (max-width: 1200px) {
  div.page-header{
    margin-top: 40px;
  }

}
@media only screen and (max-width: 1000px) {

  .navbar-right {
    float: left !important;
    margin-right: -15px;
  }

}

@media only screen and (max-width: 768px) {
  div.page-header{
    margin-top: auto;
  }


}

@media only screen and (max-width: 439px) {
  div.page-header{
    margin-top: 20px;
  }

}
@media only screen and (max-width: 615px) {
  div.btn-group.mob-view{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  div.btn-group.mob-view > label{
      border-radius: 0;
      margin-bottom: 5px;
      width: auto;
      white-space: normal;
  }

}


@media (max-width: 1200px) {
  .feature-odd, .feature-even{
    height: 66px;
    font-size: medium;
  }
  .col-md-8.col-md-offset-2 > .row .col-md-4{
    width: 33.3333333333%;
    float: left;
  }
  .col-md-8.col-md-offset-2 > .row .col-md-5{
    width: 41.6666666667%;
    float: left;
  }
  .col-md-8.col-md-offset-2 > .row .col-md-7{
    width: 58.3333333333%;
    float: left;
  }
  .col-md-8.col-md-offset-2 > .row .col-md-12{
    width: 100%;
    float: left;
  }

}
 @media (max-width: 700px) {
  .feature-odd, .feature-even{
    font-size: small;

  }

  .col-md-12.subject-block .col-md-8.col-md-offset-2{
    margin-left: 0%;
    width: 100%;
  }
  
}

