rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 30px 0 15px;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

rzslider[disabled] {
  cursor: not-allowed
}

rzslider[disabled] span.rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3
}

rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap
}

rzslider span.rz-base {
  width: 100%;
  height: 100%;
  padding: 0
}

rzslider span.rz-bar-wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 32px;
  padding-top: 16px;
  margin-top: -16px;
  box-sizing: border-box
}

rzslider span.rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 4px;
  background: #d8e0f3;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px
}

rzslider span.rz-bar.rz-selection {
  z-index: 2;
  background: #0db9f0;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px
}

rzslider span.rz-pointer {
  top: -14px;
  z-index: 3;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: #0db9f0;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px
}

rzslider span.rz-pointer:after {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 8px;
  height: 8px;
  background: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  content: ''
}

rzslider span.rz-pointer:hover:after {
  background-color: #fff
}

rzslider span.rz-pointer.rz-active:after {
  background-color: #451aff
}

rzslider span.rz-bubble {
  bottom: 16px;
  padding: 1px 3px;
  color: #55637d;
  cursor: default
}

rzslider span.rz-bubble.rz-selection {
  top: 16px
}

rzslider span.rz-bubble.rz-limit {
  color: #55637d
}

rzslider .rz-ticks {
  position: absolute;
  top: -3px;
  left: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  padding: 0 11px;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  justify-content: space-between
}

rzslider .rz-ticks .tick {
  width: 10px;
  height: 10px;
  text-align: center;
  cursor: pointer;
  background: #d8e0f3;
  border-radius: 50%
}

rzslider .rz-ticks .tick.selected {
  background: #0db9f0
}

rzslider .rz-ticks .tick .tick-value {
  position: absolute;
  top: -30px;
  transform: translate(-50%, 0)
}

/** overrides **/
rzslider {
  margin: 15px 0 15px 0;
}
rzslider span.rz-pointer {
  top: -6px;
  border-radius: 8px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: solid 1px;
  border-color: rgba(144, 157, 159, 0.8);
}

rzslider span.rz-pointer:after {
  top: 4px;
  left: 4px;

}

rzslider span.rz-pointer.rz-active:after {
  background-color: #fff;
}

.result-row .rz-bubble {
  display: none;
}

rzslider span.rz-bar.rz-selection {
  z-index: 2;
  background: #286090;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

rzslider {
  margin: 15px 0
}

rzslider span.rz-pointer {
  top: -6px;
  border-radius: 8px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid;
  border-color: rgba(144,157,159,.8)
}

rzslider span.rz-pointer:after {
  top: 4px;
  left: 4px
}

rzslider span.rz-bar.rz-selection {
  z-index: 2;
  background: #286090;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px
}

