/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/

$nsri-nav-logo: #fff;
$nsri-nav-logo-bg: #f05d53;

$nsri-nav-toggle: #fff;
$nsri-nav-toggle-bg: transparent;
$nsri-nav-toggle-border: transparent;
$nsri-nav-toggle-hover: #ccc;

$nsri-nav-bg: #3a3d46;
$nsri-nav-bg-hover: #252734;
$nsri-nav-dropdown-link: #fff;
$nsri-nav-dropdown-link-hover: #fff;
$nsri-nav-dropdown-bg: #252734;
$nsri-nav-dropdown-bg-hover: #f05d53;

$nsri-nav-link: #a6b8ce;
$nsri-nav-link-active: #f05d53;
$nsri-nav-link-hover: #f05d53;

.body-navbar-fixed {
  padding-top: 50px;
}

.main-navigation {
  background: $nsri-nav-bg;
  border: none;
  border-radius: 0;
  margin-bottom: 0;

  .navbar-brand {
    @include img-retina('../img/nsr-nav-logo.png', '../img/nsr-nav-logo_2x.png', 110px, 50px);
    min-width: 110px;
    text-align: center;
    &,
    &:hover,
    &:focus {
      color: $nsri-nav-logo;
      background-color: $nsri-nav-logo-bg;  
    }
  }
  
  .navbar-collapse {
    border-top: none;
    background-color: $nsri-nav-dropdown-bg;
    @media (min-width: $screen-sm-min) {
      background-color: $nsri-nav-bg;
    }
  }

  .navbar-btn {
    padding-bottom: 8px;
    .btn-main-nav {
      margin-left: 0;
      margin-right: 0;
      display: block;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: $screen-xs-min) {
        display: inline-block;
        margin-right: 8px;
        margin-bottom: 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    @media (min-width: $screen-xs-min) {
      padding-bottom: 8px;
    }
    @media (min-width: $screen-sm-min) {
      padding-bottom: 0;
      float: right;
    }
  }

  .navbar-nav {
    margin-top: 0;
    margin-bottom: 0;

    .open {
      .dropdown-menu {
        background-color: $nsri-nav-dropdown-bg;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
        font-size: 16px;
        padding-top: 0;
        padding-bottom: 0;

        & > li > a {
          background-color: $nsri-nav-dropdown-bg;
          color: $nsri-nav-dropdown-link;
          padding-top: 10px;
          padding-bottom: 10px;
          &:hover,
          &:focus {
            background-color: $nsri-nav-dropdown-bg-hover;
            color: $nsri-nav-dropdown-link-hover;
          }
        }

        & > .active > a {
          &,
          &:hover,
          &:focus {
            color: $nsri-nav-dropdown-link-hover;
            background-color: $nsri-nav-dropdown-bg-hover;
            @media (min-width: $screen-sm-min) {
              color: $nsri-nav-dropdown-link-hover;
              background-color: $nsri-nav-dropdown-bg-hover;
            }
          }
        }

        @media (min-width: $screen-sm-min) {
          background-color: $nsri-nav-dropdown-bg;
          border: none;
          box-shadow: none;
          color: $nsri-nav-dropdown-link;
          & > li > a {
            &:hover,
            &:focus {
              background-color: $nsri-nav-dropdown-bg-hover;
              color: $nsri-nav-dropdown-link-hover;
            }
          }
        }
      }
    }

    & > li > a {
      background-color: $nsri-nav-dropdown-bg;
      color: $nsri-nav-dropdown-link;
      &:hover,
      &:focus {
        background-color: $nsri-nav-dropdown-bg-hover;
        color: $nsri-nav-dropdown-link-hover;
      }
      @media (min-width: $screen-sm-min) {
        background-color: $nsri-nav-bg;
        color: $nsri-nav-link;
        &:hover,
        &:focus {
          background-color: $nsri-nav-bg-hover;
          color: $nsri-nav-link-hover;
        }
      }
    }

    & > .active > a {
      @media (min-width: $screen-sm-min) {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          background: $nsri-nav-link-active;
          left: 13px;
          right: 11px;
          bottom: 0;
          height: 3px;
        }
      }
      &,
      &:hover,
      &:focus {
        background-color: $nsri-nav-dropdown-bg-hover;
        color: $nsri-nav-dropdown-link-hover;
        @media (min-width: $screen-sm-min) {
          background-color: $nsri-nav-bg;
          color: $nsri-nav-link-active;
        }
      }
    }

    & > .open > a {
      &,
      &:hover,
      &:focus {
        color: $nsri-nav-link-hover;
        background-color: $nsri-nav-dropdown-bg;
      }
    }

    .current-menu-parent > a {
      @media (min-width: $screen-sm-min) {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          background: $nsri-nav-link-active;
          left: 13px;
          right: 11px;
          bottom: 0;
          height: 3px;
        }
      }
      &,
      &:hover,
      &:focus {
        background-color: $nsri-nav-dropdown-bg;
        color: $nsri-nav-link-hover;
        @media (min-width: $screen-sm-min) {
          background-color: $nsri-nav-bg;
          color: $nsri-nav-link-active;
        }
      }
    }

    @media (min-width: $screen-sm-min) {
      background-color: $nsri-nav-bg;
    }
  }

  .navbar-menu {
    @media (min-width: $screen-sm-min) {
      margin-left: 35px;
    }
  }

  .navbar-settings {
    @media (min-width: $screen-sm-min) {
      float: right;
    }

    .dropdown {
      @media (min-width: $screen-sm-min) {
        border-left: 2px solid #2f343d;
      }
    }

    .dropdown-menu {
      @media (min-width: $screen-sm-min) {
        float: right;
        left: auto;
        right: 0;
      }
    }
  }

  .navbar-toggle {
    border-color: $nsri-nav-toggle-border;
    &:hover,
    &:focus {
      background-color: $nsri-nav-toggle-bg;
      .icon-bar {
        background-color: $nsri-nav-toggle-hover;    
      }
    }

    .icon-bar {
      background-color: $nsri-nav-toggle;
    }
  }
}

@media (max-width: 435px){
  img{
    height: 36px;
  }

}
@media (max-width: 1200px){
  .container-nav{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

}

