@import '../fonts/texta/stylesheet.css';

// @import url(https://fonts.googleapis.com/css?family=Lato);
// @import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,100,300,700);

@font-face {
  font-family: 'NSR';
  src: url('../fonts/dinnext/DINPro-Regular.otf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

body {
  font-family: NSR, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  background-color: #e7e8ed;
}

h1,
h2 {
  font-family: NSR, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h3 {
  font-weight: 300;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 26px;
}

h3 {
  margin-top: 10px;
}

h4 {
  font-size: 16px;
  margin: 0;
  padding: 0 0 5px;
}

h5 {
  font-size: 12px;
  color: #a7adbb;
  margin: 0;
  padding: 0;
  display: inline-block;
}

h6 {
  font-size: 15px;
  color: #878c97;
  line-height: 1.4em;
}